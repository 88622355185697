export const formatData = (item: any) => {
  if (item.name) {
    item.label = item.abbreviation ? `${item.name} [${item.abbreviation}]` : item.name;
    item.value = item.code;
  }
  if (item.description) {
    item.label = item.code.toString();
    item.value = item.code;
  }
  return item;
};

export const searchData = (value: string, values: any[]) => {
  return values
    .filter((item) => {
      return (
        item.code?.toString().toLowerCase() === value.toString().toLowerCase() ||
        item.name?.toLowerCase() === value.toString().toLowerCase() ||
        item.description?.toLowerCase() === value.toString().toLowerCase() ||
        item.code?.toString().toLowerCase().includes(value.toString().toLowerCase()) ||
        item.name?.toLowerCase().includes(value.toString().toLowerCase()) ||
        item.description?.toLowerCase().includes(value.toString().toLowerCase()) ||
        item.label?.toLowerCase().includes(value.toString().toLowerCase())
      );
    })
    .slice(0, 20);
};

export const createEndpoint = ({
  searchValue,
  naics_code,
  agency,
  set_aside,
  posted_date_after,
  response_deadline_after,
}) => {
  let endpoint = "/opportunities/results?page=1&page_size=10";
  if (searchValue) {
    endpoint += `&search=${searchValue}`;
  }
  if (naics_code) {
    naics_code.forEach((naics_code) => {
      endpoint += `&naics_code=${naics_code.code}`;
    });
  }
  if (agency) {
    agency.forEach((agency) => {
      endpoint += `&agency=${agency.code}`;
    });
  }
  if (set_aside) {
    set_aside.forEach((set_aside) => {
      endpoint += `&set_aside=${set_aside.code}`;
    });
  }
  if (response_deadline_after) {
    response_deadline_after.forEach((data) => {
      const date = createDate(data.label);
      endpoint += `&response_deadline_after=${date}`;
    });
  }

  if (posted_date_after) {
    posted_date_after.forEach((data) => {
      const date = createDate(data.label);
      endpoint += `&posted_date_after=${date}`;
    });
  }
  return endpoint;
};

export const createDate = (type: string) => {
  const today = new Date();
  let isDate = true;
  switch (type) {
    case "Anytime":
    case "Custom date":
      isDate = false;
      break;
    case "Next Day":
      today.setHours(today.getHours() + 24);
      break;
    case "Next 2 days":
      today.setHours(today.getHours() + 48);
      break;
    case "Next 3 days":
      today.setHours(today.getHours() + 72);
      break;
    case "Next week":
      today.setHours(today.getHours() + 168);
      break;
    case "Next month":
      today.setMonth(today.getMonth() + 1);
      break;
    case "Next 3 months":
      today.setMonth(today.getMonth() + 3);
      break;
    case "Next year":
      today.setMonth(today.getMonth() + 12);
      break;
    default:
      break;
  }
  return isDate ? today.toLocaleDateString("en-US", { timeZone: "America/New_York" }) : "";
};

export const postedDateAfterOptions = [
  {
    label: "Anytime",
    value: "Anytime",
  },
  {
    label: "Next Day",
    value: "Next Day",
  },
  {
    label: "Next 2 days",
    value: "Next 2 days",
  },
  {
    label: "Next 3 days",
    value: "Next 3 days",
  },
  {
    label: "Next week",
    value: "Next week",
  },
  {
    label: "Next month",
    value: "Next month",
  },
  {
    label: "Next 3 months",
    value: "Next 3 months",
  },
  {
    label: "Next year",
    value: "Next year",
  },
];

export const formatDate = (date: string) => {
  if (!!date && typeof date === "string") {
    const newDate = new Date(date.slice(0, 10));
    return (
      newDate?.toLocaleString("en-US", {
        timeZone: "America/New_York",
        day: "numeric",
        month: "short",
        year: "numeric",
      }) || "-"
    );
  }
  return "-";
};

export const readDate = (date: string) => {
  const today = new Date();
  const receivedDate = new Date(date);
  const difference = receivedDate.getTime() - today.getTime();
  return Math.round(difference / (1000 * 3600 * 24));
};

export const readDates = (date: string) => {
  const days = readDate(date);
  switch (days) {
    case 1:
      return { label: "Next Day", value: "Next Day" };
    case 2:
      return {
        label: "Next 2 days",
        value: "Next 2 days",
      };
    case 3:
      return {
        label: "Next 3 days",
        value: "Next 3 days",
      };
    case 8:
      return {
        label: "Next week",
        value: "Next week",
      };
    case 30:
      return {
        label: "Next month",
        value: "Next month",
      };
    case 90:
      return {
        label: "Next 3 months",
        value: "Next 3 months",
      };
    case 365:
      return {
        label: "Next year",
        value: "Next year",
      };
  }
};
